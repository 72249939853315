<template>
  <Disclosure as="nav" class="bg-white shadow">
    <div class="mx-auto w-full px-2 sm:px-4 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex px-2 lg:px-0">
          <div class="flex-shrink-0 flex items-center">
            <img class="logo block lg:hidden" :src="require('/public/assets/logo.png')" alt="Your Company">
            <img class="logo hidden lg:block" :src="require('/public/assets/logo.png')" alt="Your Company">
          </div>
          <div class="hidden lg:ml-6 lg:flex lg:space-x-8">
            <!-- Dynamic Menu Items for Large Screens -->
            <Menu as="div" class="relative mt-5" v-for="(item, index) in menuItems" :key="index" @click="navigateTo(item.text)">
              <MenuButton class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out" :class="item.current ? 'border-yellow-500 text-gray-900' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'">
                {{ item.text }}
                <ChevronDownIcon class="ml-2 h-5 w-5" aria-hidden="true" v-if="item.submenu && item.submenu.length > 0" />
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute z-10 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div v-for="submenuItem in item.submenu" :key="submenuItem" @click="navigateTo(submenuItem)">
                    <MenuItem>
                      <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">{{ submenuItem }}</a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>

        <div class="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
          <div class="max-w-lg w-full lg:max-w-xs">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input id="search" name="search" class="block w-full pl-10 pr-3 py-2 border border-transparent leading-5 bg-gray-50 text-gray-900 placeholder-gray-500 focus:outline-none focus:bg-white focus:border-white focus:ring-white sm:text-sm" placeholder="Search" type="search">
            </div>
          </div>
        </div>

        <div class="flex lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton class="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>

        <div class="hidden lg:flex lg:items-center">
          <button class="flex-shrink-0 p-1 border-2 border-transparent rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100" type="button">
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button>
          <!-- Profile dropdown -->
          <Menu as="div" class="ml-4 relative flex-shrink-0">
            <div>
              <MenuButton class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" @click="navigateTo('Profile')">Your Profile</a>
                </MenuItem>
                <MenuItem>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" @click="navigateTo('Settings')">System Settings</a>
                </MenuItem>
                <MenuItem>
                  <button @click="signOut" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">Sign out</button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <DisclosurePanel class="lg:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <!-- Dynamically generated mobile menu items -->
        <a href="#" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Dashboard</a>
        <!-- Repeat for each mobile menu item as needed -->
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon, MagnifyingGlassIcon, Bars3Icon, BellIcon } from '@heroicons/vue/20/solid'
import { useRouter } from 'vue-router';

const router = useRouter();

const navigateTo = (menuItem) => {
  if (menuItem === 'User Management') {
    router.push({ name: 'Users' }); // Use the route name you defined
  }
  if (menuItem === 'Dashboard') {
    router.push({ name: 'Dashboard' }); // Use the route name you defined
  }
  if (menuItem === 'Profile') {
    router.push({ name: 'profile' }); // Use the route name you defined
  }
  if (menuItem === 'Settings') {
    router.push({ name: 'Settings' }); // Use the route name you defined
  }
  // Add more conditions for other menu items if necessary
};

const menuItems = [
  {
    text: 'Dashboard',
    current: true,
    submenu: []
  },
  {
    text: 'Admin',
    current: false,
    submenu: ['User Management']
  }
];

const signOut = () => {
  localStorage.removeItem('token'); // Remove the token from localStorage
  router.push({ name: 'login' }); // Redirect to /login page
  window.location.reload();
};
</script>

<style scoped>
.logo {
  width: 160px;
}
</style>
