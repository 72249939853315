import axios from 'axios';

const DriverService = {
  async getDrivers() {
    return axios.get('/drivers');
  },
  async getDriver(id) {
    return axios.get(`/drivers/${id}`);
  },
  async createDriver(data) {
    return axios.post('/drivers', data);
  },
  async updateDriver(id, data) {
    return axios.put(`/drivers/${id}`, data);
  },
  async deleteDriver(id) {
    return axios.delete(`/drivers/${id}`);
  }
};

export default DriverService;
