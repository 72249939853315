import axios from 'axios';

const ScheduleService = {
  async getSchedules() {
    return axios.get('/schedules');
  },
  async getSchedule(id) {
    return axios.get(`/schedules/${id}`);
  },
  async createSchedule(data) {
    return axios.post('/schedules', data);
  },
  async updateSchedule(data) {
    return axios.put('/schedules', data);
  },  
  async deleteSchedule(schedule_id) {
    return axios.put(`/schedules/remove/${schedule_id}`);
  }
};

export default ScheduleService;
