import axios from 'axios';

const BusService = {
  async getBuses() {
    return axios.get('/buses');
  },
  async getBus(id) {
    return axios.get(`/buses/${id}`);
  },
  async createBus(data) {
    return axios.post('/buses', data);
  },
  async updateBus(id, data) {
    return axios.put(`/buses/${id}`, data);
  },
  async deleteBus(id) {
    return axios.delete(`/buses/${id}`);
  }
};

export default BusService;
