import { Vue, createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

// import main css
// import './css/main.css'
import '/index.css'
import store from './store';  // <-- Make sure this import is correct

axios.defaults.baseURL = process.env.VUE_APP_APIURL;
// In your main.js or a dedicated Axios configuration file

// Request interceptor to set the Authorization header dynamically
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

createApp(App).use(store).use(router).mount('#app')
