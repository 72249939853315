<template>
  <div class="p-4">
    <div class="flex items-center mb-4">
      <div class="font-bold text-xl">Route Schedule</div>
      <button @click="addNewSchedule" class="bg-blue-500 text-white px-3 py-1 rounded flex items-center ml-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
        </svg>
      </button>
    </div>

    <!-- New Schedule Form -->
    <div v-if="newSchedule.expanded" class="mb-6 border p-4 rounded-lg">
      <div class="font-bold text-lg mb-4">New Schedule</div>
      <div class="flex items-center space-x-4 mb-4">
        <label class="font-semibold">Bus #:</label>
        <select v-model="newSchedule.busId" class="border p-1 rounded w-32">
          <option v-for="bus in buses" :key="bus.id" :value="bus.id">
            {{ bus.fleet }}
          </option>
        </select>
      </div>
      <div class="accordion-content">
        <div v-for="(day, dayIndex) in days" :key="dayIndex" class="flex items-center justify-between mb-4">
          <div v-if="day === 'Monday'" class="schedule-column w-1/6 text-yellow-500 text-right">
            <button @click="applyMondayToAll" class="bg-white text-blue-500 border border-blue-500 px-2 py-1 rounded ml-2 text-xs">
              Apply To All
            </button>
            {{ day }}
          </div>
          <div v-else class="schedule-column w-1/6 text-yellow-500 text-right">{{ day }}</div>
          <div class="schedule-column w-1/6">
            <label class="font-semibold">Driver AM:</label>
            <select v-model="newSchedule.days[day].driver_id_am" class="border p-1 rounded w-full">
              <option v-for="driver in drivers" :key="driver.id" :value="driver.id">
                {{ driver.fname }} {{ driver.lname }}
              </option>
            </select>
          </div>
          <div class="schedule-column w-1/6">
            <label class="font-semibold whitespace-nowrap">AM Departure:</label>
            <input type="time" v-model="newSchedule.days[day].departure_am" class="border p-1 rounded w-full">
          </div>
          <div class="schedule-column w-1/6">
            <label class="font-semibold">Driver PM:</label>
            <select v-model="newSchedule.days[day].driver_id_pm" class="border p-1 rounded w-full">
              <option v-for="driver in drivers" :key="driver.id" :value="driver.id">
                {{ driver.fname }} {{ driver.lname }}
              </option>
            </select>
          </div>
          <div class="schedule-column w-1/6">
            <label class="font-semibold whitespace-nowrap">PM Departure:</label>
            <input type="time" v-model="newSchedule.days[day].departure_pm" class="border p-1 rounded w-full">
          </div>
        </div>
      </div>
      <div class="flex justify-center mt-4 space-x-4">
        <button @click="saveNewSchedule" class="bg-green-500 text-white px-6 py-2 rounded w-40">Save Schedule</button>
        <button @click="cancelNewSchedule" class="bg-gray-500 text-white px-6 py-2 rounded w-40">Cancel</button>
      </div>
    </div>

    <!-- Existing Schedules -->
    <div v-for="(schedule, index) in groupedSchedules" :key="index" class="relative mb-6 border p-4 rounded-lg">
      <div class="flex items-center justify-between mb-2">
        <div class="flex items-center space-x-4">
          <div class="flex items-center space-x-2">
            <label class="font-semibold">Bus #:</label>
            <select v-model="schedule.bus_id" @change="updateSchedule(schedule)" class="border p-1 rounded w-32">
              <option v-for="bus in buses" :key="bus.id" :value="bus.id">
                {{ bus.fleet }}
              </option>
            </select>
          </div>
          <div class="text-sm font-semibold">
            {{ getDriverName(schedule.days.Monday.driver_id_am) }}: {{ formatTime(schedule.days.Monday.departure_am) }} | {{ getDriverName(schedule.days.Monday.driver_id_pm) }}: {{ formatTime(schedule.days.Monday.departure_pm) }}
          </div>
        </div>
        <div class="flex items-center space-x-2">
          <button @click="deleteSchedule(schedule.schedule_id)" class="bg-red-500 text-white px-3 py-1 rounded">Delete</button>
          <button @click="toggleSchedule(index)" class="bg-gray-300 rounded-full w-8 h-8 flex items-center justify-center">
            <span v-if="schedule.expanded">▲</span>
            <span v-else>▼</span>
          </button>
        </div>
      </div>

      <transition name="accordion">
        <div v-show="schedule.expanded" class="accordion-content">
          <div v-for="(day, dayIndex) in days" :key="dayIndex" class="flex items-center justify-between mb-4">
            <div class="schedule-column w-1/6 text-yellow-500 text-right">{{ day }}</div>
            <div class="schedule-column w-1/6">
              <label class="font-semibold">Driver AM:</label>
              <select v-model="schedule.days[day].driver_id_am" @change="updateSchedule(schedule)" class="border p-1 rounded w-full">
                <option v-for="driver in drivers" :key="driver.id" :value="driver.id">
                  {{ driver.fname }} {{ driver.lname }}
                </option>
              </select>
            </div>
            <div class="schedule-column w-1/6">
              <label class="font-semibold whitespace-nowrap">AM Departure:</label>
              <input type="time" v-model="schedule.days[day].departure_am" @change="updateSchedule(schedule)" class="border p-1 rounded w-full">
            </div>
            <div class="schedule-column w-1/6">
              <label class="font-semibold">Driver PM:</label>
              <select v-model="schedule.days[day].driver_id_pm" @change="updateSchedule(schedule)" class="border p-1 rounded w-full">
                <option v-for="driver in drivers" :key="driver.id" :value="driver.id">
                  {{ driver.fname }} {{ driver.lname }}
                </option>
              </select>
            </div>
            <div class="schedule-column w-1/6">
              <label class="font-semibold whitespace-nowrap">PM Departure:</label>
              <input type="time" v-model="schedule.days[day].departure_pm" @change="updateSchedule(schedule)" class="border p-1 rounded w-full">
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import ScheduleService from '@/services/ScheduleService';
import DriverService from '@/services/DriverService';
import BusService from '@/services/BusService';

const groupedSchedules = ref([]);
const drivers = ref([]);
const buses = ref([]);
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
const newSchedule = ref({
  busId: null,
  expanded: false,
  days: {
    Monday: { driver_id_am: null, departure_am: '', driver_id_pm: null, departure_pm: '', status: 1 },
    Tuesday: { driver_id_am: null, departure_am: '', driver_id_pm: null, departure_pm: '', status: 1 },
    Wednesday: { driver_id_am: null, departure_am: '', driver_id_pm: null, departure_pm: '', status: 1 },
    Thursday: { driver_id_am: null, departure_am: '', driver_id_pm: null, departure_pm: '', status: 1 },
    Friday: { driver_id_am: null, departure_am: '', driver_id_pm: null, departure_pm: '', status: 1 }
  }
});

let debounceTimeout;

function debounce(func, delay) {
  return function(...args) {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => func.apply(this, args), delay);
  };
}

const debouncedUpdateSchedule = debounce(async function(schedule) {
  try {
    const formattedSchedule = {
      schedule_id: schedule.schedule_id,
      bus_id: schedule.bus_id,
      days: Object.keys(schedule.days).map(dayKey => {
        const day = schedule.days[dayKey];
        return {
          id: day.id,
          day_of_week: days.indexOf(dayKey) + 1,
          bus_id: schedule.bus_id,
          driver_id_am: day.driver_id_am,
          departure_am: normalizeTimeFormat(day.departure_am),
          driver_id_pm: day.driver_id_pm,
          departure_pm: normalizeTimeFormat(day.departure_pm),
          status: day.status
        };
      })
    };

    const response = await ScheduleService.updateSchedule(formattedSchedule);
    alert('Schedule updated successfully!');
  } catch (error) {
    console.error('Failed to update schedule:', error);
    alert('Failed to update schedule. Please try again.');
  }
}, 500); // Adjust delay (500 ms) as needed

function updateSchedule(schedule) {
  debouncedUpdateSchedule(schedule);
}

async function fetchSchedules() {
  try {
    const response = await ScheduleService.getSchedules();

    groupedSchedules.value = response.data.map(schedule => ({
      ...schedule,
      bus_id: schedule.bus_id,
      days: Object.fromEntries(
        schedule.days.map(day => [
          getDayName(day.day_of_week),
          {
            id: day.id,
            driver_id_am: day.driver_id_am ?? null,
            departure_am: day.departure_am ?? '',
            driver_id_pm: day.driver_id_pm ?? null,
            departure_pm: day.departure_pm ?? '',
            status: day.status ?? 1
          }
        ])
      )
    }));
  } catch (error) {
    console.error('Failed to load schedules:', error);
  }
}

function getDriverName(driverId) {
  const driver = drivers.value.find(driver => driver.id === driverId);
  return driver ? `${driver.fname} ${driver.lname}` : 'N/A';
}

function applyMondayToAll() {
  const mondayValues = newSchedule.value.days.Monday;
  days.slice(1).forEach(day => {
    newSchedule.value.days[day] = {
      driver_id_am: mondayValues.driver_id_am,
      departure_am: mondayValues.departure_am,
      driver_id_pm: mondayValues.driver_id_pm,
      departure_pm: mondayValues.departure_pm,
      status: mondayValues.status
    };
  });
}

async function deleteSchedule(scheduleid) {
  try {
    await ScheduleService.deleteSchedule(scheduleid);
    alert('Schedule deleted successfully!');
    await fetchSchedules();
  } catch (error) {
    console.error('Failed to delete schedule:', error);
    alert('Failed to delete schedule. Please try again.');
  }
}

async function fetchDrivers() {
  try {
    const response = await DriverService.getDrivers();
    drivers.value = response.data;
  } catch (error) {
    console.error('Failed to load drivers:', error);
  }
}

async function fetchBuses() {
  try {
    const response = await BusService.getBuses();
    buses.value = response.data;
  } catch (error) {
    console.error('Failed to load buses:', error);
  }
}

function toggleSchedule(index) {
  groupedSchedules.value[index].expanded = !groupedSchedules.value[index].expanded;
}

function addNewSchedule() {
  newSchedule.value.expanded = true;
}

function cancelNewSchedule() {
  resetNewScheduleForm();
}

async function saveNewSchedule() {
  try {
    const formattedSchedule = {
      bus_id: newSchedule.value.busId,
      days: days.map((day, index) => ({
        day_of_week: index + 1, // Assuming 1 = Monday, 2 = Tuesday, etc.
        driver_id: newSchedule.value.days[day].driver_id_am,
        departure: normalizeTimeFormat(newSchedule.value.days[day].departure_am),
        departure_type: 'am',
        status: newSchedule.value.days[day].status
      }))
    };

    days.forEach((day, index) => {
      formattedSchedule.days.push({
        day_of_week: index + 1,
        driver_id: newSchedule.value.days[day].driver_id_pm,
        departure: normalizeTimeFormat(newSchedule.value.days[day].departure_pm),
        departure_type: 'pm',
        status: newSchedule.value.days[day].status
      });
    });

    const response = await ScheduleService.createSchedule(formattedSchedule);
    await fetchSchedules();
    resetNewScheduleForm();
    alert('New schedule created successfully!');
  } catch (error) {
    console.error('Failed to create schedule:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      alert(`Failed to create schedule: ${error.response.data.message}`);
    } else {
      alert('Failed to create schedule. Please try again.');
    }
  }
}

function normalizeTimeFormat(time) {
  console.log(time)
  if (!time) return null;
  if (time.length === 5) {
    // If the time is in 'H:i' format, append ':00'
    return `${time}:00`;
  }
  if (time.length === 8) {
    // If the time is already in 'H:i:s' format, return as is
    return time;
  }
  // Handle any unexpected format cases (optional)
  console.warn('Unexpected time format:', time);
  return null;
}

function resetNewScheduleForm() {
  newSchedule.value.expanded = false;
  newSchedule.value.busId = null;
  newSchedule.value.days = {
    Monday: { driver_id_am: null, departure_am: '', driver_id_pm: null, departure_pm: '', status: 1 },
    Tuesday: { driver_id_am: null, departure_am: '', driver_id_pm: null, departure_pm: '', status: 1 },
    Wednesday: { driver_id_am: null, departure_am: '', driver_id_pm: null, departure_pm: '', status: 1 },
    Thursday: { driver_id_am: null, departure_am: '', driver_id_pm: null, departure_pm: '', status: 1 },
    Friday: { driver_id_am: null, departure_am: '', driver_id_pm: null, departure_pm: '', status: 1 }
  };
}

function getDayName(dayNumber) {
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return dayNames[dayNumber] || 'Unknown';
}

function formatTime(time) {
  if (!time) return "N/A";
  const [hours, minutes] = time.split(':');
  const hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 || 12;
  return `${formattedHour}:${minutes} ${ampm}`;
}

onMounted(() => {
  fetchBuses();
  fetchDrivers();
  fetchSchedules();
});
</script>

<style scoped>
.accordion-enter-active, .accordion-leave-active {
  transition: all 0.3s ease;
}
.accordion-enter, .accordion-leave-to {
  opacity: 0;
  height: 0;
  padding: 0;
}
.schedule-column {
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 8px;
}
</style>
